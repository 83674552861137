import puzzlesData from './puzzles.json';

export const puzzles = puzzlesData.map(puzzle => puzzle.string);

export function getColorFromClass(char) {
    switch (char) {
        case '-': return 'grey';
        case 'X': return 'red';
        case 'A': return 'green';
        case 'B': return 'blue';
        case 'C': return 'yellow';
        case 'D': return 'purple';
        case 'E': return 'orange';
        case 'F': return 'pink';
        case 'G': return 'lime';
        case 'H': return 'cyan';
        case 'I': return 'magenta';
        case 'J': return 'brown';
        case 'K': return 'teal';
        case 'L': return 'coral';
        case 'M': return 'navy';
        case 'N': return 'olive';
        case 'O': return 'darkblue';
        case 'P': return 'darkgreen';
        case 'Q': return 'darkred';
        case 'R': return 'darkmagenta';
        case 'S': return 'darkcyan';
        case 'T': return 'darkgoldenrod';
        case 'U': return 'darkgray';
        case 'V': return 'darkkhaki';
        case 'W': return 'darkolivegreen';
        case 'Y': return 'darkorchid';
        case 'Z': return 'darksalmon';
        default: return 'white';
    }
}
