import React from 'react';

function PuzzleID({ id, difficulty }) {
    let difficultyClass = '';
    switch (difficulty) {
        case 'Easy':
            difficultyClass = 'easy';
            break;
        case 'Very Easy':
            difficultyClass = 'easy';
            break;
        case 'Medium':
            difficultyClass = 'medium';
            break;
        case 'Hard':
            difficultyClass = 'hard';
            break;
        case 'Bangkok':
            difficultyClass = 'bangkok';
            break;
        default:
            break;
    }

    return (
        <div className="heading-container">
            <div className="puzzle-id">Puzzle #: {id}</div>
            <div className={`puzzle-difficulty ${difficultyClass}`}>{difficulty}</div>
        </div>
    );
}


export default PuzzleID;
