import React, { useState, useEffect } from 'react';
import puzzlesData from './puzzles.json';

function Menu({ setSelectedPuzzle, setDisplayPuzzle, puzzleLoaded, setPuzzleLoaded }) {
    const [timer, setTimer] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [timerStatus, setTimerStatus] = useState("stopped"); // "started", "paused", or "stopped"

    const [selectedDifficulty, setSelectedDifficulty] = useState("Bangkok");
    const handleDifficultyChange = (e) => { setSelectedDifficulty(e.target.value); };

    useEffect(() => {
        let interval;
        if (isActive) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 10); // Update every 10ms
            }, 10);
        } else if (!isActive && timer !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, timer]);

    const startTimer = () => {
        if (timerStatus === "stopped" || (timerStatus === "paused" && timer === 0)) {
            setTimer(0);
            setIsActive(true);
            setTimerStatus("started");
        } else if (timerStatus === "started") {
            setIsActive(false);
            setTimerStatus("paused");
        } else if (timerStatus === "paused") {
            setIsActive(true);
            setTimerStatus("started");
        }
    };

    const resetTimer = () => {
        setTimer(0);
    };

    const formatTime = () => {
        const minutes = Math.floor(timer / 60000);
        const seconds = Math.floor((timer - minutes * 60000) / 1000);
        const milliseconds = (timer - minutes * 60000 - seconds * 1000).toString().substr(0, 2);
        return `${minutes}:${seconds.toString().padStart(2, '0')}.${milliseconds}`;
    };

    const getButtonText = () => {
        if (timerStatus === "stopped" || (timerStatus === "paused" && timer === 0)) {
            return "Start Timer";
        } else if (timerStatus === "started") {
            return "Pause Timer";
        } else if (timerStatus === "paused") {
            return "Resume";
        }
    };

    const newPuzzle = () => {
        let filteredPuzzles = puzzlesData.filter(puzzle =>
            (selectedDifficulty === "All Puzzles" || puzzle.Difficulty === selectedDifficulty) &&
            !puzzle.string.includes('K')
        );

        if (filteredPuzzles.length > 0) {
            const randomIndex = Math.floor(Math.random() * filteredPuzzles.length);
            setSelectedPuzzle(filteredPuzzles[randomIndex]);
            setDisplayPuzzle(filteredPuzzles[randomIndex]);
            setPuzzleLoaded(false); // Set puzzleLoaded to false when new puzzle is selected
        } else {
            console.log("No puzzles available that meet the criteria.");
        }
    };

    useEffect(() => {
        let filteredPuzzles = puzzlesData.filter(puzzle => 
            (selectedDifficulty === "All Puzzles" || puzzle.Difficulty === selectedDifficulty) &&
            !puzzle.string.includes('K')
        );
        console.log("Number of puzzles: ", filteredPuzzles.length);
    }, [selectedDifficulty, setSelectedPuzzle, setDisplayPuzzle, setPuzzleLoaded]); // Run only when the selectedDifficulty changes

    return (
        <div className="menu-container">
            {
                puzzleLoaded ? (
                    <div className="new-puzzle-container">
                        <button className="new-puzzle-button" onClick={newPuzzle}>
                        New Puzzle </button>
                        <select value={selectedDifficulty} onChange={handleDifficultyChange} className="difficulty-selector">
                        <option value="All Puzzles">All Puzzles</option>
                        <option value="Very Easy">Very Easy</option>
                        <option value="Easy">Easy</option>
                        <option value="Medium">Medium</option>
                        <option value="Hard">Hard</option>
                        <option value="Bangkok">Bangkok</option>
                        </select>
                    </div>
                ) : (
                    <span>Loading...</span>
                )
            }

            {puzzleLoaded && (
                <>
                    <button onClick={startTimer}>
                        {getButtonText()}
                    </button>
                    {timerStatus === "paused" && <button onClick={resetTimer}>Reset</button>}
                    <span>{formatTime()}</span>
                </>
            )}
        </div>
    );
}

export default Menu;
