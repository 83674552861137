import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { getColorFromClass } from './utils';
import puzzlesData from './puzzles.json';
import Menu from './Menu';
import PuzzleID from './PuzzleID';
import NoSleep from 'nosleep.js';

function App() {
    const [selectedPuzzle, setSelectedPuzzle] = useState(puzzlesData[0]);
    const [displayPuzzle, setDisplayPuzzle] = useState(puzzlesData[0]);
    const [puzzleLoaded, setPuzzleLoaded] = useState(false);
    const [rotationDegrees, setRotationDegrees] = useState(0); // Updated state to track degrees of rotation
    const step = useRef(0);
    const newDisplayPuzzle = useRef({...displayPuzzle});

    useEffect(() => {
        const noSleep = new NoSleep();
        const enableNoSleep = () => {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
            console.log('NoSleep enabled: Preventing screen from sleeping.');
        };

        document.addEventListener('click', enableNoSleep, false);

        return () => {
            noSleep.disable();
            console.log('NoSleep disabled: Screen can now sleep.');
        };
    }, []);

    useEffect(() => {
        step.current = 0;
        newDisplayPuzzle.current = {...displayPuzzle};
        const steps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        const timer = setInterval(() => {
            if (step.current >= steps.length) {
                clearInterval(timer);
                setPuzzleLoaded(true);
            } else {
                const stepChar = steps[step.current];
                newDisplayPuzzle.current.string = selectedPuzzle.string.replace(new RegExp(`[${steps.slice(step.current + 1).join('')}]`, 'g'), '-');
                setDisplayPuzzle({...newDisplayPuzzle.current});
                if (stepChar !== '-') {
                    step.current++;
                }
            }
        }, 50);
        return () => clearInterval(timer);
    }, [selectedPuzzle]);

    // Updated function to increase rotation counter-clockwise
    const increaseRotation = () => {
        setRotationDegrees(prevDegrees => prevDegrees - 90);
    };

    return (
        <div className="App">
            <div className="grid-container" style={{transform: `rotate(${rotationDegrees}deg)`}}>
                {[...Array(6)].map((_, rowIndex) => (
                    <div className="grid-row" key={rowIndex}>
                    {[...Array(6)].map((_, colIndex) => (
                        <div 
                        className={`grid-square ${getColorFromClass(displayPuzzle.string[rowIndex * 6 + colIndex])} 
                                    ${rowIndex === 2 && colIndex === 5 ? 'target-element' : ''}`} 
                        key={colIndex}
                        ></div>
                    ))}
                    </div>
                ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PuzzleID id={selectedPuzzle.id} difficulty={selectedPuzzle.Difficulty} />
                {/* Arrow icon next to the difficulty display */}
                <div style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={increaseRotation}>
                    &gt;
                </div>
            </div>
            <Menu setSelectedPuzzle={setSelectedPuzzle} setDisplayPuzzle={setDisplayPuzzle} puzzleLoaded={puzzleLoaded} setPuzzleLoaded={setPuzzleLoaded} />
        </div>
    );
}

export default App;
